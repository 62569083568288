import { Box, Container, LinearProgress } from "@mui/material";
import DeleteStyles from "./delete.module.css";
import { Header } from "pages/landing/components/header";
import { Footer } from "pages/landing/components/footer";

interface Props {}

export const DeleteAccountPage: React.FC<Props> = (props) => {
  return (
    <div className={DeleteStyles.LandingPage}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          minHeight: "100vh",
          height: "100%",
        }}
      >
        <Header />
        <Box sx={{ marginTop: "150px" }}>
          <div className={DeleteStyles.Title}>How to Delete your Data</div>
          <div className={DeleteStyles.paragraph}>
            To delete your data, you must delete your account. This will remove
            your account and associated app metadata (ex. posts, profile info,
            etc).
          </div>
          <div className={DeleteStyles.Subtitle}>
            Requesting Account Deletion
          </div>
          <div className={DeleteStyles.paragraph}>
            {`You can easily request account deletion from within the app. First
            navigate to your profile (right most option in the app navigation).
            Navigate to your account settings (gear icon on the top left). 
            Select Delete Account > Request Account Deletion.`}
          </div>
          <div className={DeleteStyles.paragraph}>
            Once you've requested account deletion, your request will be
            processed within 24hrs.
          </div>
          <div className={DeleteStyles.paragraph}>
            Questions? Please contact us at&nbsp;
            <a href="mailto:hello@pushieapp.com">hello@pushieapp.com</a>
          </div>
        </Box>
        <Footer />
      </Container>
    </div>
  );
};
